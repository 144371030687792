import React, { useState, useEffect } from 'react';

function App() {
  const [date, setDate] = useState(new Date());
  const [utcDate, setUtcDate] = useState("");
  const [usDate, setUsDate] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const utcOptions = { timeZone: "UTC", hour12: false, hour: "numeric", minute: "numeric", second: "numeric" };
    const usOptions = { timeZone: "America/New_York", hour12: false, hour: "numeric", minute: "numeric", second: "numeric" };
    setUtcDate(date.toLocaleTimeString('en-US', utcOptions));
    setUsDate(date.toLocaleTimeString('en-US', usOptions));
  }, [date]);

  const style = {
    backgroundColor: 'black',
    color: 'white',
    textAlign: 'center',
    fontSize: '5rem',
    padding: '1rem',
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  };

  return (
    <div style={style}>
      <h1>{date.toLocaleTimeString()}</h1>
      <h2>UTC: {utcDate}</h2>
      <h2>US: {usDate}</h2>
    </div>
  );
}

export default App;
